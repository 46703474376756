<template>
  <div>
    <h1>Cases</h1>
    <div>
      <hr>
      <div class="row mb-1">
        <div class="col-6">
          <!-- add button -->
          <div v-if="UserType === 'User'">
            <div class="col-4 pr-0 pl-0 D-flex justify-space-arround">
              <b-button
                block
                variant="primary"
                style="
                  justify-content: space-around;
                  display: flex;
                  border-radius: 0;
                "
                @click="$router.push('/cases/add')"
              >
                <span>+ Add Case</span>
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-3 center-justified">
          Include closed cases <toggle-button @change="IncludeClosedCases = $event.value" />
        </div>
        <div class="col-3">
          <b-form-group>
            <div class="d-flex align-items-right">
              <b-form-input
                v-model="searchFilterTerm"
                placeholder="Filter Claims"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: DateCreated -->
          <span
            v-if="props.column.field === 'DateCreated'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.DateCreated | dateFormat }}</span>
          </span>

          <!-- Column: Subject -->
          <span v-else-if="props.column.field === 'Subject'">
            <router-link
              :to="'subject/' + props.row.SubjectID "
            >{{ props.row.Subject }}</router-link>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: InternalNumber -->
          <span v-else-if="props.column.field === 'InternalNumber'">
            <router-link
              :to="'cases/' + props.row.CaseID + '/detail'"
            >{{ props.row.InternalNumber }}</router-link>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing {{ displayStartIndex }} - {{ displayEndIndex }} of
                {{ totalResultCount }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['100', '150', '200']"
                class="mx-1"
                @input="
                  (value) => {
                    props.perPageChanged({ currentPerPage: value });
                    onPageSizeChanged({ currentPerPage: value });
                  }
                "
              />
              <span class="text-nowrap">per page</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :value="1"
                :total-rows="totalResultCount"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
                @page-click="onPageChanged"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import APIService from "@core/utils/APIService";
import {mapGetters, mapMutations} from "vuex";

const apiService = new APIService();


export default {
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
  },
  filters: {
    dateFormat(val) {
      if (val) {
        const date = new Date(val);
        const options = {month: '2-digit', day: '2-digit', year: 'numeric'}
        if (date) return date.toLocaleDateString("en-US", options);
      }
      if (val) return val.substr(0, 10);
      return val
    }
  },
  data() {
    return {
      pageLength: 100,
      currentPage: 1,
      displayStartIndex: 1,
      displayEndIndex: 100,
      dir: false,
      totalResultCount: 0,
      awaitingSearch: false,
      IncludeClosedCases: false,
      UserType: "",
      columns: [
        {
          label: "Claim Number",
          field: "ClaimFileNo",
        },
        {
          label: "Internal Number",
          field: "InternalNumber",
        },
        {
          label: "Subject",
          field: "Subject",
        },
        {
          label: "Location",
          field: "Location",
        },
        {
          label: "Created",
          field: "DateCreated",
        },
        {
          label: "Hours",
          field: "Hours",
        },
        {
          label: "Client",
          field: "ClientName",
        },
        {
          label: "Status",
          field: "Status",
        },
      ],
      rows: [],
      searchFilterTerm: "",
      status: [
        {
          1: "Active",
          2: "Disabled",
        },
        {
          1: "light-primary",
          2: "light-danger",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getClaimsFilter: "search/getClaimsFilter"
    }),
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    searchFilterTerm: function (val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.loadCasesPage(1);
          this.awaitingSearch = false;
        }, 1500); // ms delay search debounce
      }
      this.setClaimsFilter(val)
      this.awaitingSearch = true;
    },
    IncludeClosedCases: function (val) {
      this.loadCasesPage(1);
    }
  },
  mounted() {
    this.searchFilterTerm = this.getClaimsFilter;
    this.UserType = localStorage.getItem("userType");
    this.loadCasesPage(1)
  },
  methods: {
    ...mapMutations({
      setClaimsFilter: "search/setClaimsFilter"
    }),
    loadCasesPage(pageNumber) {
      this.rows = [];

      var pageZeroIndex = pageNumber - 1;
      var startIndex = pageZeroIndex * this.pageLength;

      this.displayStartIndex = startIndex + 1;
      this.displayEndIndex = this.pageLength + this.displayStartIndex - 1;

      if (this.searchFilterTerm.length) {
        apiService
            .get(
                "cases/search?searchFilterTerm=" +
                encodeURIComponent(this.searchFilterTerm) +
                "&skip=" +
                startIndex +
                "&max=" +
                this.pageLength +
                "&activeCases=" +
                !this.IncludeClosedCases
            )
            .then((response) => {
              this.loadCaseResults(response.data);
            });
        return;
      }
      apiService
          .get("cases/search?skip=" + startIndex + "&max=" + this.pageLength + "&activeCases=" + !this.IncludeClosedCases)
          .then((response) => {
            this.loadCaseResults(response.data);
          });
    },
    loadCaseResults(responseData) {
      this.rows = responseData.Cases;
      this.totalResultCount = responseData.TotalCount;
      this.displayEndIndex = this.rows.length + this.displayStartIndex - 1;
    },
    onPageChanged(bvEvent, page) {
      this.loadCasesPage(page);
    },
    onPageSizeChanged(newPageSize) {
      this.loadCasesPage(this.currentPage);
      this.pageLength = newPageSize.currentPerPage;
    },
    showToast(variant, position, timeout, data) {
      this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "InfoIcon",
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          }
      );
    },
  },
};
</script>

<style>

</style>
